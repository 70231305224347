import { createAsyncThunk } from '@reduxjs/toolkit';
import { loggedIn, login } from 'api/auth';
import { getRoles } from 'api/role';
import { AxiosResponse } from 'axios';
import { errorMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  ILoggedInRequest,
  ILoginResponse,
  TLoggedInResponse,
} from 'interfaces';

import { createThunk } from 'utils';
import { setLStorageMany } from 'utils/helpers/localStorage';

import { getAccountMeThunk } from '../account/actions';

export const loginThunk = createThunk<ILoginResponse>(
  `${reducersNames.AUTH}/${asyncActionsNames.LOGIN}`,
  login
);

export const loggedInThunk = createAsyncThunk<
  AxiosResponse<TLoggedInResponse>,
  ILoggedInRequest
>(
  `${reducersNames.LOGGED_IN}/${asyncActionsNames.LOGIN}`,
  async (request, thunkAPI) => {
    try {
      const loggedData = await loggedIn(request).then(async (res) => {
        const {
          data: { refresh_token, token },
        } = res;

        setLStorageMany({
          refresh_token,
          token,
        });

        await thunkAPI.dispatch(getAccountMeThunk());

        return res;
      });

      return loggedData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRolesThunk = createThunk(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_ROLES}`,
  getRoles,
  { errorMessage: errorMessages.GET_PRIVILEGES_ERROR }
);
