import { FC, useEffect, useMemo } from 'react';
import { useCountdown } from 'usehooks-ts';

import { getDurationString } from 'utils';

interface IStreamDurationProps {
  isCounting: boolean;
  isStopped: boolean;
}

export const StreamDuration: FC<IStreamDurationProps> = ({
  isCounting,
  isStopped,
}) => {
  const [milliseconds, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 0,
      intervalMs: 1000,
      isIncrement: true,
      countStop: Number.MAX_SAFE_INTEGER,
    });

  useEffect(() => {
    if (isStopped) {
      resetCountdown();

      return;
    }

    if (isCounting) {
      startCountdown();
    } else {
      stopCountdown();
    }
  }, [isCounting, isStopped]);

  const timeString = useMemo(
    () => getDurationString(milliseconds),
    [milliseconds]
  );

  if (isStopped) return null;

  return <span className="tpg-b1 text-error">{timeString}</span>;
};
