import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  banAccount,
  getAccount,
  getAccountMe,
  updateAccountRole,
} from 'api/account';
import { changeEmail, changePassword } from 'api/auth';
import { AxiosResponse } from 'axios';
import { errorMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { IPlayerResponse } from 'interfaces/player';
import { ID } from 'types';

import { createThunk, notify } from 'utils';

import { getAllPlayersThunk } from '../player/actions';

export const getAccountThunk = createThunk<IPlayerResponse, ID>(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.GET_ACCOUNT}`,
  getAccount,
  { errorMessage: errorMessages.GET_ACCOUNT }
);

export const getAccountMeThunk = createThunk<IPlayerResponse, void>(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.GET_ACCOUNT}`,
  getAccountMe,
  { errorMessage: errorMessages.GET_ACCOUNT }
);

export const updateAccountRoleThunk = createThunk(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.UPDATE_ACCOUNT_ROLE}`,
  updateAccountRole,
  { errorMessage: errorMessages.UPDATE_ACCOUNT }
);

export const updateAccountEmailThunk = createThunk(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.UPDATE_ACCOUNT_EMAIL}`,
  changeEmail
);

export const updateAccountPasswordThunk = createThunk(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.UPDATE_ACCOUNT_PASSWORD}`,
  changePassword,
  { errorMessage: errorMessages.UPDATE_ACCOUNT }
);

export const banAccountThunk = createAsyncThunk<AxiosResponse<string>, string>(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.DELETE_ACCOUNT}`,
  async (request, thunkAPI) => {
    try {
      const data = await banAccount(request).then(async (res) => {
        await thunkAPI.dispatch(getAllPlayersThunk());

        return res;
      });

      return data;
    } catch (error) {
      notify.error(errorMessages.DELETE_ACCOUNT);

      return thunkAPI.rejectWithValue(error);
    }
  }
);
