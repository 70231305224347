import { EUserRole, roleWeights } from 'constants/profile';
import { IAccountRole } from 'interfaces';

export const getHighestRole = (roles: IAccountRole[]): EUserRole =>
  roles.reduce<EUserRole>((acc, { role_name }) => {
    if (roleWeights[role_name] > roleWeights[acc]) {
      acc = role_name;
    }

    return acc;
  }, EUserRole.USER);
