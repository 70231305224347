import { FC, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as RightArrow } from 'images/newIcons/rightArrow.svg';
import { IHistory } from 'types/history';

import { HistoryDescription } from './HistoryDescription';

type CollapseHistoryProps = IHistory;

export const CollapseHistory: FC<CollapseHistoryProps> = ({
  date,
  content,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpenHistory = () => {
    setOpen(!isOpen);
  };

  return (
    <div key={date} className="flex flex-col gap-[16px]">
      <div
        className="flex flex-row items-center gap-[4px] cursor-pointer"
        onClick={handleOpenHistory}
      >
        <RightArrow
          className={cn('scale-110 [&>path]:fill-tpg_title', {
            'rotate-90': isOpen,
          })}
        />
        <span className="text-tpg_base tpg-b2">{date}</span>
      </div>
      <div
        className={cn(
          'flex flex-col gap-[16px] overflow-hidden h-0 transition-[height]',
          {
            'h-full': isOpen,
          }
        )}
      >
        {content.map(({ title, time, description }) => (
          <HistoryDescription
            key={time}
            title={title}
            time={time}
            description={description}
            isCollapse={!isOpen}
          />
        ))}
      </div>
    </div>
  );
};
