import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { authSelector, loginThunk } from 'store';

import { Button } from 'components/ui';
import { Loader } from 'components/ui/Loader';

import './style.scss';

export const Login = () => {
  const { redirectUrl, pending, error } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const tryRedirect = () => {
    dispatch(loginThunk());
  };

  useEffect(() => {
    tryRedirect();
  }, []);

  return (
    <div className="login">
      <div className="login_container">
        {pending && (
          <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
        )}
        {(error || !redirectUrl) && (
          <>
            <h2>{error}</h2>
            <Button
              type="button"
              className="w-full mt-8"
              isLoading={pending}
              onClick={tryRedirect}
              title="Попробовать снова"
            />
          </>
        )}
      </div>
    </div>
  );
};
