import { FC, useState } from 'react';

import { ExpandableText } from 'components/ExpandableText';
import { Tag } from 'components/ui/Tag';

interface IDescriptionProps {
  text: string;
  tags?: string[];
}

export const VideoDescription: FC<IDescriptionProps> = ({ text, tags }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div className="flex flex-col gap-[16px] w-full">
      <div className="flex flex-row gap-y-[8px] gap-x-[4px] flex-wrap max-w-[350px]">
        {tags && tags.map((tag) => tag && <Tag key={tag} label={tag} />)}
      </div>
      <ExpandableText
        isExpanded={isExpanded}
        setExpanded={handleToggle}
        text={text}
      />
    </div>
  );
};
