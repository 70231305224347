import { useLocation, useParams } from 'react-router-dom';

import { RecordSection } from 'components/RecordSection';

export const RecordsScreen = () => {
  const { state } = useLocation();

  const { id } = useParams();

  const clanId = state?.clanId ?? id;

  return (
    <div className="flex flex-col gap-[16px]">
      {clanId ? (
        <RecordSection clanId={clanId} />
      ) : (
        <span>Нет данных о подразделении</span>
      )}
    </div>
  );
};
