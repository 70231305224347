import { FC, MouseEvent, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LiveKitRoom } from '@livekit/components-react';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useClickOutside } from 'hooks';
import { authSelector } from 'store/slices/auth/selectors';
import { roomActions, roomSelector } from 'store/slices/room';
import { deleteRoomThunk } from 'store/slices/room/actions';
import { rolesValuesMap } from 'types/player';

import { updateHiddenRoomsParams } from 'components/FilterStreams/utils';
import { ViewerPlayer } from 'components/StreamPlayerContainer/components/ViewerPlayer';
import { roomHideController } from 'utils';

interface RoomProps {
  livekitName: string;
  publisherName: string;
  roomName: string;
  accountId: string;
  clanId?: string;
  clanName?: string;
  token?: string;
  classNamePlayer?: string;
  heightPlayer?: number;
  isMain?: boolean;
  isMainBlock?: boolean;
  isShowSwitchButtonMain?: boolean;
  onSelectMainRoom?: (name: string) => void;
  onFullscreenChange?: (flag: boolean) => void;
}

export const Room: FC<RoomProps> = ({
  livekitName,
  publisherName,
  roomName,
  accountId,
  clanName,
  clanId,
  token,
  classNamePlayer,
  heightPlayer,
  isMain,
  isMainBlock,
  isShowSwitchButtonMain,
  onSelectMainRoom,
  onFullscreenChange,
}) => {
  const [isShowButtons, setShowButtons] = useState(false);

  const { id, roles } = useAppSelector(authSelector);

  const { treeRooms } = useAppSelector(roomSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowButtons(false)
  );

  const highPriorityRoles = roles.filter(
    (role) =>
      role.role_name === EUserRole.ADMIN || role.role_name === EUserRole.ROOT
  );

  const isAdminForPlayer =
    (!!highPriorityRoles.filter((role) => role.clan_id === clanId).length &&
      accountId !== rolesValuesMap.root) ||
    id === rolesValuesMap.root;

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();

    setShowButtons(true);
  };

  const handleHideRoom = (livekitName: string) => {
    if (clanId) {
      const newTreeRooms = roomHideController(treeRooms, clanId, livekitName);

      dispatch(roomActions.updateTreeRooms(newTreeRooms));

      const newParams = updateHiddenRoomsParams(
        treeRooms,
        searchParams,
        newTreeRooms
      );

      setSearchParams(newParams);
    }
  };

  const handleDeleteRoom = () => {
    if (clanId) {
      dispatch(
        deleteRoomThunk({
          clanId,
          roomName,
          params: { save_recording: false },
        })
      );
    }
  };

  return (
    <div
      ref={containerRef}
      className="relative"
      onContextMenu={handleContextMenu}
    >
      <LiveKitRoom token={token} serverUrl={process.env.REACT_APP_LIVEKIT_API}>
        <ViewerPlayer
          livekitName={livekitName}
          isMuted={true}
          publisherName={publisherName}
          clanName={clanName}
          onFullscreenChange={onFullscreenChange}
          className={classNamePlayer}
          height={heightPlayer}
          isMain={isMain}
          isMainBlock={isMainBlock}
          isShowSwitchButtonMain={isShowSwitchButtonMain}
          onSelectMainRoom={onSelectMainRoom}
        />
      </LiveKitRoom>
      {isShowButtons && (!isMainBlock || (isMainBlock && isMain)) && (
        <div className="absolute z-[1] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] max-w-[110px] right-0 flex flex-col rounded-[10px] bg-ultrablack border border-solid border-tpg_light">
          {!isMainBlock && (
            <span
              className="cursor-pointer tpg-c2 pt-[6px] px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
              onClick={() => handleHideRoom(livekitName)}
            >
              Скрыть
            </span>
          )}
          {isAdminForPlayer && (
            <span
              className="cursor-pointer tpg-c2 pb-[6px] pt-[3px] px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
              onClick={handleDeleteRoom}
            >
              Завершить
            </span>
          )}
        </div>
      )}
    </div>
  );
};
