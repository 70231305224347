import { FC, useState } from 'react';
import cn from 'classnames';
import { IOption } from 'interfaces';
import { TSaveRecordParams } from 'interfaces/record';
import { gameTypeOptions, tagOptions } from 'mock';

import { DeleteModal } from 'components/DeleteModal';
import { Modal } from 'components/ui';
import { getOptionsByFilteredTags } from 'utils';

import { RecordModal } from './RecordModal';

interface ControllerRecordModalProps {
  type: 'edit' | 'create';
  onResume: () => void;
  onSave: (params?: TSaveRecordParams) => void;
  tags?: string[];
  description?: string;
}

export const ControllerRecordModal: FC<ControllerRecordModalProps> = ({
  type,
  onResume,
  onSave,
  tags,
  description,
}) => {
  const initialRecordDescription =
    type === 'edit' && description ? description : '';

  const initialGameTypes =
    type === 'edit' && tags
      ? getOptionsByFilteredTags(gameTypeOptions, tags)
      : [];

  const initialTags =
    type === 'edit' && tags ? getOptionsByFilteredTags(tagOptions, tags) : [];

  const [recordDescription, setRecordDescription] = useState(
    initialRecordDescription || ''
  );

  const [selectedGameTypes, setSelectedGameTypes] =
    useState<IOption[]>(initialGameTypes);

  const [selectedTags, setSelectedTags] = useState<IOption[]>(initialTags);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const handleSave = () => {
    onSave({
      save_recording: true,
      recording_description: recordDescription,
      recording_tags: [
        ...selectedGameTypes.map((item) => item.value),
        ...selectedTags.map((item) => item.value),
      ],
    });
  };

  const handleRemoveRecord = () => {
    onSave();
    setOpenDeleteModal(false);
  };

  const handleSelectTags = (value: IOption[]) => {
    setSelectedTags(value);
  };

  const handleSelectGameTypes = (value: IOption[]) => {
    setSelectedGameTypes(value);
  };

  const handleChangeRecordDescription = (value: string) => {
    setRecordDescription(value);
  };

  return (
    <Modal onClose={onResume} isBlurred>
      <div
        className={cn(
          'max-w-[460px] w-[460px] flex flex-col gap-[24px] justify-center border border-solid border-bright rounded-[10px] bg-dark p-[48px]',
          isOpenDeleteModal && 'max-w-[530px] w-[530px]'
        )}
      >
        {isOpenDeleteModal && (
          <DeleteModal
            onClose={() => setOpenDeleteModal(false)}
            onRemove={handleRemoveRecord}
            title="Вы уверены, что хотите удалить запись?"
          />
        )}
        <RecordModal
          type={type}
          recordDescription={recordDescription}
          selectedGameTypes={selectedGameTypes}
          selectedTags={selectedTags}
          onChangeDescription={handleChangeRecordDescription}
          onSelectGameTypes={handleSelectGameTypes}
          onSelectTags={handleSelectTags}
          onResume={onResume}
          onSave={handleSave}
          onRemove={() => setOpenDeleteModal(true)}
        />
      </div>
    </Modal>
  );
};
