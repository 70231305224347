import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clansSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';

import { Loader } from 'components/ui/Loader';

export const UserStreamsLayout = () => {
  const { clans, pending } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClansThunk());
  }, []);

  if (pending) {
    return (
      <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
    );
  }

  if (!clans.length && !pending) {
    return <span>Данные не загрузились</span>;
  }

  return <Navigate to={`/department/${clans[0].id}`} replace />;
};
