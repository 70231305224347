import { Link } from 'react-router-dom';
import { navigation } from 'constants/routes';
import { useAppSelector } from 'hooks';
import { authSelector } from 'store';

import './style.scss';

export const NotFound = () => {
  const { role } = useAppSelector(authSelector);

  return (
    <div className="not-found">
      <div className="not-found__body">
        <span className="tpg-h1">404</span>
        <h1 className="tpg-h2">Страница не найдена</h1>
        <Link className="tpg-b1" to={role ? navigation[role] : '/'}>
          Вернуться на главную
        </Link>
      </div>
    </div>
  );
};
