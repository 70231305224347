import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { NavLinkProps } from 'interfaces';

import './style.scss';

const Container: FC<Omit<NavLinkProps, 'link'> & { className?: string }> = memo(
  ({ passiveIcon, activeIcon, title, isActive, onClick, className }) => (
    <div
      className={cn(
        'header-link',
        {
          'header-link__active': isActive,
        },
        'py-2 px-5',
        className
      )}
      onClick={onClick}
    >
      {isActive ? activeIcon : passiveIcon}
      <p className="header-link__title tpg-b1">{title}</p>
    </div>
  )
);

Container.displayName = 'Container';

const HeaderLink: FC<NavLinkProps> = ({
  passiveIcon,
  title,
  link,
  isActive,
  onClick,
  containerClassName,
}) => {
  if (link) {
    return (
      <Link to={link} className="my-auto">
        <Container
          passiveIcon={passiveIcon}
          title={title}
          isActive={isActive}
          onClick={onClick}
          className={containerClassName}
        />
      </Link>
    );
  }

  return (
    <Container
      passiveIcon={passiveIcon}
      title={title}
      isActive={isActive}
      onClick={onClick}
    />
  );
};

export default memo(HeaderLink);
