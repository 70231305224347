import { FC, useEffect, useState } from 'react';

import { ExpandableText } from 'components/ExpandableText';

interface IHistoryDescriptionProps {
  title: string;
  time: string;
  description?: string;
  isCollapse: boolean;
}

export const HistoryDescription: FC<IHistoryDescriptionProps> = ({
  description,
  isCollapse,
  time,
  title,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isCollapse) {
      setExpanded(false);
    }
  }, [isCollapse]);

  return (
    <div key={title} className="flex flex-col gap-[8px]">
      <div className="flex flex-row justify-between">
        <span className="tpg-input">{title}</span>
        <span className="tpg-input">{time}</span>
      </div>
      {description && (
        <ExpandableText
          text={description}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          className="!text-tpg_base"
        />
      )}
    </div>
  );
};
