import { ELStorageKeys } from 'constants/localStorage';

import { getLStorage, setLStorage } from './helpers/localStorage';

export const setToken = (token: string) =>
  setLStorage([ELStorageKeys.authToken, token]);

export const setTileToken = (token: string) =>
  setLStorage(['tile_token', token]);

export const getToken = () => getLStorage(ELStorageKeys.authToken);

export const getTileToken = () => getLStorage('tile_token');
