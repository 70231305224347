import { EUserRole } from './profile';
import { appRoutes } from './routes';

export const navs = {
  [EUserRole.MODERATOR]: [
    {
      link: appRoutes.STREAMS,
      title: 'Эфир',
    },
    {
      link: appRoutes.RECORDS,
      title: 'Записи',
    },
    // ToDo: temporarily hidden
    // {
    //   link: appRoutes.MAP,
    //   title: 'Карта',
    // },
  ],
  [EUserRole.USER]: [
    {
      link: appRoutes.STREAMS,
      title: 'Эфир',
    },
    {
      link: appRoutes.RECORDS,
      title: 'Записи',
    },
  ],
  [EUserRole.ADMIN]: [
    {
      link: appRoutes.STREAMS,
      title: 'Эфир',
    },
    {
      link: appRoutes.RECORDS,
      title: 'Записи',
    },
    // ToDo: temporarily hidden
    // {
    //   link: appRoutes.MAP,
    //   title: 'Карта',
    // },
    {
      link: appRoutes.ADMIN_DEPARTMENTS,
      title: 'Подразделения',
    },
    {
      link: appRoutes.ADMIN_USERS,
      title: 'Пользователи',
    },
  ],
  [EUserRole.ROOT]: [
    {
      link: appRoutes.STREAMS,
      title: 'Эфир',
    },
    {
      link: appRoutes.RECORDS,
      title: 'Записи',
    },
    // ToDo: temporarily hidden
    // {
    //   link: appRoutes.MAP,
    //   title: 'Карта',
    // },
    {
      link: appRoutes.ADMIN_DEPARTMENTS,
      title: 'Подразделения',
    },
    {
      link: appRoutes.ADMIN_USERS,
      title: 'Пользователи',
    },
  ],
};
