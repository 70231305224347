import { useCallback, useEffect, useRef } from 'react';
import { escapeDownSingleton } from 'services/listeners/escapeDown';

type TCallback = (...args: any) => void;

export const useEscapeDown = (callback: TCallback, target = true) => {
  useEffect(() => {
    const id = escapeDownSingleton.subscribe(() => target && callback());

    if (!target) {
      escapeDownSingleton.unsubscribe(id);
    }

    return () => escapeDownSingleton.unsubscribe(id);
  }, [callback, target]);
};

export const useEscapeDownControlled = (callback: TCallback) => {
  const idRef = useRef<number | null>(null);

  const subscribe = useCallback(() => {
    const id = escapeDownSingleton.subscribe(callback);

    idRef.current = id;
  }, [callback]);

  const unsubscribe = useCallback(() => {
    escapeDownSingleton.unsubscribe(idRef.current);
    idRef.current = null;
  }, []);

  return [subscribe, unsubscribe];
};
