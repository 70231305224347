import { useEffect, useState } from 'react';

export type TBreakPoint =
  | 'mobile'
  | 'tablet'
  | 'tablet-landscape'
  | 'desktop'
  | 'monitor'
  | 'large-screen';

const getBreakPoint = (width: number): TBreakPoint => {
  switch (true) {
    case width <= 580:
      return 'mobile';
    case width >= 581 && width <= 750:
      return 'tablet';
    case width >= 750 && width <= 1024:
      return 'tablet-landscape';
    case width >= 1025 && width <= 1440:
      return 'desktop';
    case width >= 1440 && width <= 2500:
      return 'monitor';
    default:
      return 'large-screen';
  }
};

export const useBreakPoint = () => {
  const [breakPoint, setBreakPoint] = useState<TBreakPoint>('mobile');

  useEffect(() => {
    setBreakPoint(getBreakPoint(window.innerWidth));
  }, []);

  useEffect(() => {
    const getInnerWidth = () => {
      setBreakPoint(getBreakPoint(window.innerWidth));
    };

    window.addEventListener('resize', getInnerWidth);

    return () => window.removeEventListener('resize', getInnerWidth);
  }, []);

  return breakPoint;
};
