import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { HEADER_HEIGHT, PADDING } from 'constants/common';
import { useBreakPoint, useClickOutside, useWindowSize } from 'hooks';
import { IRoomParams, ITreeRooms } from 'interfaces';

import { Room } from 'components/Room';

import { RoomList } from './RoomList';

interface MainRoomPanelProps {
  isOpenFullScreen: boolean;
  mainRoom: IRoomParams;
  rooms: IRoomParams[];
  onSelectMainRoom: (name: string) => void;
  clanId?: string;
  onFullscreenChange?: (flag: boolean) => void;
}

const convertToRoomTree = (rooms: IRoomParams[]) =>
  rooms.reduce((treeRooms: ITreeRooms[], room) => {
    const existingClan = treeRooms.find((data) => data?.clanId === room.clanId);

    if (existingClan) {
      existingClan.rooms.push(room);
    } else {
      room.clanName &&
        room.clanId &&
        treeRooms.push({
          clanName: room.clanName,
          clanId: room.clanId,
          rooms: [room],
          isHidden: false,
        });
    }

    return treeRooms;
  }, []);

const GAP = 8;

export const MainRoomPanel: FC<MainRoomPanelProps> = ({
  isOpenFullScreen,
  mainRoom,
  rooms,
  onSelectMainRoom,
  clanId: id,
  onFullscreenChange,
}) => {
  const [isOpenList, setOpenList] = useState(false);

  const [heightPlayer, setHeightPlayer] = useState(0);

  const [heightPanel, setHeightPanel] = useState(0);

  const { height } = useWindowSize();

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () => setOpenList(false));

  useEffect(() => {
    const heightScreen = isOpenFullScreen ? height : height - HEADER_HEIGHT;

    if (height) {
      const bottomPanelHeight = Math.ceil((heightScreen * 15) / 100);

      const difference = heightScreen - bottomPanelHeight - PADDING - GAP;

      setHeightPlayer(difference);

      setHeightPanel(bottomPanelHeight);
    }
  }, [height, isOpenFullScreen]);

  const {
    livekitName,
    publisherName,
    token,
    roomName,
    accountId,
    clanName,
    clanId,
  } = mainRoom;

  const filteredRoom = useMemo(
    () => rooms.filter((room) => room.livekitName !== livekitName),
    [rooms, livekitName]
  );

  const breakPoint = useBreakPoint();

  const count =
    (breakPoint === 'desktop' && 4) ||
    (breakPoint === 'tablet-landscape' && 3) ||
    ((breakPoint === 'tablet' || breakPoint === 'mobile') && 1) ||
    5;

  const roomList =
    filteredRoom.length > count
      ? filteredRoom.slice(0, count - 1)
      : filteredRoom;

  const remainingList =
    filteredRoom.length > count
      ? count > 1
        ? filteredRoom.slice(count)
        : filteredRoom
      : [];

  const treeRooms = convertToRoomTree(remainingList);

  const handleStreamSelect = (name: string) => {
    onSelectMainRoom(name);
  };

  const handleListOpen = () => {
    setOpenList(!isOpenList);
  };

  const quantityHiddenRoom =
    filteredRoom.length - roomList.length - (count > 1 ? 1 : 0);

  return (
    <div className="flex flex-col gap-[8px]">
      <Room
        key={livekitName}
        accountId={accountId}
        livekitName={livekitName}
        roomName={roomName}
        publisherName={publisherName}
        token={token}
        clanId={clanId || id}
        clanName={clanName}
        onFullscreenChange={onFullscreenChange}
        heightPlayer={heightPlayer}
        isMain={true}
        isMainBlock={true}
        onSelectMainRoom={onSelectMainRoom}
      />
      <div className={`relative grid grid-cols-${count} gap-[8px]`}>
        {roomList.map(
          ({
            livekitName,
            publisherName,
            token,
            roomName,
            accountId,
            clanId,
            clanName,
          }) => (
            <div
              key={livekitName}
              className="cursor-pointer"
              onClick={() => handleStreamSelect(livekitName)}
            >
              <Room
                accountId={accountId}
                livekitName={livekitName}
                roomName={roomName}
                publisherName={publisherName}
                token={token}
                clanId={clanId || id}
                clanName={clanName}
                onFullscreenChange={onFullscreenChange}
                heightPlayer={heightPanel}
                isMain={false}
                isMainBlock={true}
                onSelectMainRoom={onSelectMainRoom}
              />
            </div>
          )
        )}
        {!!remainingList.length && (
          <div
            ref={containerRef}
            className="relative flex items-center justify-center rounded-[10px] bg-light border-solid border-tpg_light border-[1px] cursor-pointer"
            style={{ minHeight: `${heightPanel}px` }}
            onClick={handleListOpen}
          >
            <span className="tpg-h4">{quantityHiddenRoom}</span>
            {isOpenList && (
              <RoomList rooms={treeRooms} onSelectMainRoom={onSelectMainRoom} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
