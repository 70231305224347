import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

export const useViewerToken = (
  viewerName: string | null,
  streamerName: string
) => {
  const [viewerToken, setViewerToken] = useState('');

  useEffect(() => {
    const getOrCreateViewerToken = async () => {
      const SESSION_VIEWER_TOKEN_KEY = `${streamerName}-viewer-token`;

      const sessionToken = sessionStorage.getItem(SESSION_VIEWER_TOKEN_KEY);

      if (sessionToken) {
        const payload = jwtDecode(sessionToken);

        if (payload.exp) {
          const expiry = new Date(payload.exp * 1000);

          if (expiry < new Date()) {
            sessionStorage.removeItem(SESSION_VIEWER_TOKEN_KEY);

            setViewerToken('');
            sessionStorage.setItem(SESSION_VIEWER_TOKEN_KEY, '');

            return;
          }
        }

        setViewerToken(sessionToken);
      } else {
        setViewerToken('');
        sessionStorage.setItem(SESSION_VIEWER_TOKEN_KEY, '');
      }
    };

    getOrCreateViewerToken();
  }, [viewerName, streamerName]);

  return viewerToken;
};
