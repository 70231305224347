import { EUserRole } from '../constants/profile';
import { rolesTranslateMap } from '../types/player';

export const NEW_ID = 'new';

export const mockHistory = [
  {
    date: '3 января 2023 года',
    content: [
      {
        title: 'Игрок бигбой начал эфир',
        time: '12:20',
      },
    ],
  },
  {
    date: '2 января 2023 года',
    content: [
      {
        title: 'Игрок Литлбой закончил эфир',
        time: '12:20',
        description:
          'Оrem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare. At urna viverra non suspendisse neque, lorem. Pretium condimentum pellentesque gravida id',
      },
      {
        title: 'Игрок Литлбой начал эфир',
        time: '09:20',
        description:
          'Оrem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare. At urna viverra non suspendisse neque, lorem. Pretium condimentum pellentesque gravida id',
      },
    ],
  },
];

export const roleOptions = [
  {
    value: '00000000000000000000000004',
    label: rolesTranslateMap[EUserRole.USER],
  },
  {
    value: '00000000000000000000000005',
    label: rolesTranslateMap[EUserRole.MODERATOR],
  },
  {
    value: '00000000000000000000000006',
    label: rolesTranslateMap[EUserRole.ADMIN],
  },
];

export const gameTypeOptions = [
  { label: 'Разведка', value: 'разведка' },
  { label: 'Доразведка', value: 'доразведка' },
  { label: 'Корректировка', value: 'корректировка' },
  { label: 'Объективный контроль', value: 'объективный контроль' },
  { label: 'Работа сбросами', value: 'работа сбросами' },
];

export const tagOptions = [
  { label: 'Пехота', value: 'пехота' },
  { label: 'Бронетехника', value: 'бронетехника' },
  { label: 'Артиллерия', value: 'артиллерия' },
  { label: 'Транспорт', value: 'транспорт' },
  { label: 'Блиндаж', value: 'блиндаж' },
  { label: 'КНП', value: 'кнп' },
];
