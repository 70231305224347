import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { HEADER_HEIGHT, PADDING } from 'constants/common';
import { useBreakPoint } from 'hooks';
import { useWindowSize } from 'hooks/useWindowSize';
import { IRoomParams } from 'interfaces';

import { EmtyScreen } from 'components/EmtyScreen';
import { MainRoomPanel } from 'components/MainRoomPanel';
import { Room } from 'components/Room';

interface PanelProps {
  isOpenFullScreen: boolean;
  onBack: () => void;
  rooms: IRoomParams[];
  clanId?: string;
  onFullscreenChange?: (
    flag: boolean,
    livekitName: string,
    clanId?: string
  ) => void;
}

const GAP = 5;

export const Panel: FC<PanelProps> = ({
  isOpenFullScreen,
  onBack,
  rooms,
  clanId: id,
  onFullscreenChange,
}) => {
  const breakPoint = useBreakPoint();

  const { height } = useWindowSize();

  const [heightPlayer, setHeightPlayer] = useState(0);

  const [mainRoom, setMainRoom] = useState<IRoomParams>();

  const [searchParams, setSearchParams] = useSearchParams();

  const mainRoomParams = searchParams.get('mainRoom');

  const unhiddenRooms = rooms.filter((room) => !room.isHidden);

  const heightScreen = useMemo(
    () => (isOpenFullScreen ? height : height - HEADER_HEIGHT),
    [isOpenFullScreen, height]
  );

  useEffect(() => {
    const count =
      (unhiddenRooms.length <= 2 && 1) ||
      (unhiddenRooms.length >= 3 && unhiddenRooms.length <= 6 && 2) ||
      (unhiddenRooms.length >= 7 && unhiddenRooms.length <= 12 && 3) ||
      (unhiddenRooms.length >= 13 && unhiddenRooms.length <= 20 && 4) ||
      5;

    if (height) {
      const value = heightScreen / count;

      if (value) {
        const difference =
          value - (unhiddenRooms.length > 2 ? PADDING - GAP : PADDING);

        setHeightPlayer(difference);
      }
    }
  }, [height, unhiddenRooms, isOpenFullScreen]);

  useEffect(() => {
    if (mainRoom?.livekitName) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        mainRoom: mainRoom.livekitName,
      });
    }
  }, [mainRoom]);

  useEffect(() => {
    const room = unhiddenRooms.find(
      (room) => room.livekitName === (mainRoom?.livekitName || mainRoomParams)
    );

    if (!room && (mainRoom || mainRoomParams) && unhiddenRooms.length) {
      const filteredRoom = unhiddenRooms.filter(
        (room) => room.livekitName !== mainRoom?.livekitName
      );

      setMainRoom(filteredRoom[0]);
    }

    if (mainRoomParams && !mainRoom && unhiddenRooms.length) {
      const room = unhiddenRooms.find(
        (room) => room.livekitName === mainRoomParams
      );

      if (room?.token) {
        setMainRoom(room);
      }
    }

    if (unhiddenRooms.length === 1 && mainRoomParams) {
      searchParams.delete('mainRoom');

      setMainRoom(undefined);

      setSearchParams(Object.fromEntries(searchParams));
    }

    if (!unhiddenRooms.length && mainRoom) {
      searchParams.delete('mainRoom');

      setSearchParams(Object.fromEntries(searchParams));
    }

    if (!unhiddenRooms.length && !mainRoomParams) {
      setMainRoom(undefined);
    }
  }, [unhiddenRooms, mainRoom, mainRoomParams]);

  const gridSize =
    ((breakPoint === 'mobile' ||
      breakPoint === 'tablet' ||
      unhiddenRooms.length === 1) &&
      'grid-cols-1') ||
    (unhiddenRooms.length >= 2 && unhiddenRooms.length <= 4 && 'grid-cols-2') ||
    (((unhiddenRooms.length >= 5 && unhiddenRooms.length <= 9) ||
      breakPoint === 'tablet-landscape') &&
      'grid-cols-3') ||
    (unhiddenRooms.length >= 10 &&
      unhiddenRooms.length <= 16 &&
      'grid-cols-4') ||
    'grid-cols-5';

  const handleMainStreamSelect = (livekitName: string) => {
    if (livekitName) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        mainRoom: livekitName,
      });

      const room = unhiddenRooms.find(
        (room) => room.livekitName === livekitName
      );

      setMainRoom((prevRoom) =>
        prevRoom?.livekitName === livekitName ? undefined : room
      );
    } else {
      searchParams.delete('mainRoom');

      setSearchParams(Object.fromEntries(searchParams));

      setMainRoom(undefined);
    }
  };

  return (
    <div className="flex flex-col gap-[16px] w-[calc(100%+48px)] m-[-24px] p-[8px]">
      {isOpenFullScreen && (
        <div
          onClick={onBack}
          className="flex flex-row gap-[5px] absolute left-[50%] translate-x-[-50%] top-0 py-[6px] px-[16px] z-[1] bg-ultrablack/80 rounded-b-[5px]"
        >
          <span className="tpg-c2 text-bright_product">esc</span>
          <span className="tpg-c2">вернуть обратно</span>
        </div>
      )}
      {unhiddenRooms?.length ? (
        mainRoom ? (
          <MainRoomPanel
            isOpenFullScreen={isOpenFullScreen}
            mainRoom={mainRoom}
            rooms={unhiddenRooms}
            clanId={id}
            onFullscreenChange={(flag) =>
              onFullscreenChange?.(
                flag,
                mainRoom.livekitName,
                mainRoom.clanId || id
              )
            }
            onSelectMainRoom={handleMainStreamSelect}
          />
        ) : (
          <div className={cn(`grid ${gridSize} gap-[5px] w-full`)}>
            {unhiddenRooms.map(
              ({
                livekitName,
                publisherName,
                token,
                clanId,
                clanName,
                roomName,
                accountId,
              }) => (
                <Room
                  key={livekitName}
                  accountId={accountId}
                  livekitName={livekitName}
                  roomName={roomName}
                  publisherName={publisherName}
                  token={token}
                  clanId={clanId || id}
                  clanName={clanName}
                  onFullscreenChange={(flag) =>
                    onFullscreenChange?.(flag, livekitName, clanId || id)
                  }
                  heightPlayer={heightPlayer}
                  isMain={!!mainRoom}
                  isShowSwitchButtonMain={unhiddenRooms.length > 1}
                  onSelectMainRoom={handleMainStreamSelect}
                />
              )
            )}
          </div>
        )
      ) : (
        <EmtyScreen
          isOpenFullScreen={isOpenFullScreen}
          text="Нет ни одного эфира"
        />
      )}
    </div>
  );
};
