import { FC, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { LiveKitRoom } from '@livekit/components-react';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useBreakPoint, useRoles } from 'hooks';
import { TSaveRecordParams } from 'interfaces/record';
import { accountSelector } from 'store';
import { roomActions, roomSelector } from 'store/slices/room';
import {
  createRoomThunk,
  deleteRoomThunk,
  getRecordingSignalThunk,
} from 'store/slices/room/actions';

import { BrowserHostStream } from 'components/StreamPlayerContainer/components/BrowserHostStream';
import { Loader } from 'components/ui/Loader';

interface IStreamLaunchProps {
  username: string;
}

export const StreamLaunch: FC<IStreamLaunchProps> = ({ username }) => {
  const { roles } = useAppSelector(accountSelector);

  const { pending, selectedRoom } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  const breakPoint = useBreakPoint();

  const saveRecordParams = useRef<TSaveRecordParams>();

  const { state: clanHost }: { state?: string } = useLocation();

  const getClanHost = useRoles([EUserRole.ADMIN, EUserRole.USER], roles);

  const clanId = useMemo(() => {
    if (!clanHost) return getClanHost();

    return clanHost;
  }, [clanHost, roles]);

  const roomName = `${username}_stream`;

  const handleSignalRecord = () => {
    if (clanId) {
      dispatch(getRecordingSignalThunk({ clanId, roomName }));
    }
  };

  useEffect(() => {
    if (clanId) {
      dispatch(
        createRoomThunk({
          roomName,
          clanId,
        })
      );

      return () => {
        dispatch(
          deleteRoomThunk({
            clanId,
            roomName,
            params: saveRecordParams.current || { save_recording: false },
          })
        );

        dispatch(roomActions.setMethod(null));
      };
    }
  }, [clanId]);

  useEffect(() => {
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  if (pending)
    return (
      <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
    );

  return (
    <LiveKitRoom
      className={cn('flex flex-col gap-[16px] w-[630px] m-auto', {
        'w-[330px]': breakPoint === 'mobile' || breakPoint === 'tablet',
      })}
      token={selectedRoom?.token}
      serverUrl={process.env.REACT_APP_LIVEKIT_API}
      options={{
        disconnectOnPageLeave: false,
      }}
    >
      <BrowserHostStream
        isRecording={selectedRoom?.isRecording}
        onDeleteRoom={(params) => {
          saveRecordParams.current = params;

          dispatch(roomActions.setMethod(null));
        }}
        onPublish={handleSignalRecord}
      />
    </LiveKitRoom>
  );
};
