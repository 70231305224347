import { FC, useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { getClansThunk } from 'store/slices/clan/actions';

import { AllClansStreamsPanel } from './AllClansStreamsPanel';

interface StreamsPanelScreenProps {
  isOpenFullScreen: boolean;
  onCloseFullScreen: (flag: boolean) => void;
}

export const StreamsPanelScreen: FC<StreamsPanelScreenProps> = ({
  isOpenFullScreen,
  onCloseFullScreen,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClansThunk());

    return () => {
      onCloseFullScreen(false);
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        onCloseFullScreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleClickBack = () => {
    const event = new Event('customExitFullscreen');
    document.dispatchEvent(event);
  };

  useEffect(() => {
    const handleCustomExitFullscreen = () => {
      onCloseFullScreen(false);

      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen().then(() => onCloseFullScreen(false));
      }
    };

    document.addEventListener(
      'customExitFullscreen',
      handleCustomExitFullscreen
    );

    return () => {
      document.removeEventListener(
        'customExitFullscreen',
        handleCustomExitFullscreen
      );
    };
  }, []);

  return (
    <AllClansStreamsPanel
      isOpenFullScreen={isOpenFullScreen}
      onBack={handleClickBack}
    />
  );
};
