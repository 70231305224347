import { configureStore } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';
import { enableMapSet } from 'immer';

import { recordsReducer } from './slices/records';
import { roomReducer } from './slices/room';
import {
  accountReducer,
  authReducer,
  clanReducer,
  mapReducer,
  playerReducer,
  streamReducer,
} from './slices';

enableMapSet();

export const store = configureStore({
  reducer: {
    [reducersNames.MAP]: mapReducer,
    [reducersNames.AUTH]: authReducer,
    [reducersNames.STREAM]: streamReducer,
    [reducersNames.CLAN]: clanReducer,
    [reducersNames.PLAYER]: playerReducer,
    [reducersNames.ACCOUNT]: accountReducer,
    [reducersNames.ROOM]: roomReducer,
    [reducersNames.RECORDS]: recordsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export * from './slices';
