import { useCallback } from 'react';
import { EUserRole } from 'constants/profile';
import { IAccountRole } from 'interfaces';

export const useRoles = (
  permisions: EUserRole[],
  userRoles: IAccountRole[]
) => {
  const getClanHostId = useCallback(
    () =>
      userRoles.find((role) => permisions.includes(role.role_name))?.clan_id,
    [userRoles]
  );

  return getClanHostId;
};
