import { useState } from 'react';
import { Id, toast } from 'react-toastify';

import { notify } from 'utils';

interface IToastParams {
  type: 'warning' | 'error' | 'success';
  message: string;
}

export const useToastManager = (limit = 3) => {
  const [_activeToasts, setActiveToasts] = useState<Id[]>([]);

  const showToast = (type: IToastParams['type'], message: string) => {
    toast.clearWaitingQueue();

    const newToastId = notify[type](message);

    setActiveToasts((prevToasts) => {
      if (prevToasts.length >= limit) {
        const [oldestToastId, ...rest] = prevToasts;

        notify.dismiss(oldestToastId);

        return [...rest, newToastId];
      }

      return [...prevToasts, newToastId];
    });
  };

  return { showToast };
};
