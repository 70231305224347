import { FC } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';
import { IHistory } from 'types/history';

import { CollapseHistory } from './CollapseHistory';

interface HistoryInfoProps {
  history: IHistory[];
}

export const HistoryInfo: FC<HistoryInfoProps> = ({ history }) => {
  const breakPoint = useBreakPoint();

  return (
    <div
      className={cn(
        'w-6/12 min-h-full overflow-auto flex flex-col gap-[32px] p-[16px]',
        {
          'w-full': breakPoint === 'mobile' || breakPoint === 'tablet',
        }
      )}
    >
      {history.map((info) => (
        <CollapseHistory key={info.date} {...info} />
      ))}
    </div>
  );
};
