import { createSlice } from '@reduxjs/toolkit';
import { ELStorageKeys } from 'constants/localStorage';
import { errorMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { reducersNames } from 'constants/reducers';
import { IAuthState } from 'interfaces/store';

import { getToken } from 'utils';
import { getHighestRole } from 'utils/getHighestRole';
import { clearLStorageMany, getLStorage } from 'utils/helpers/localStorage';

import { getAccountMeThunk } from '../account/actions';

import { loggedInThunk, loginThunk } from './actions';

const initialState: IAuthState = {
  isLogged: !!getToken(),
  pending: false,
  error: null,
  role: null,
  roles: [],
  id: '',
  accessToken: getToken(),
  refreshToken: getLStorage(ELStorageKeys.refreshToken),
  username: '',
  redirectUrl: null,
};

const authSlice = createSlice({
  name: reducersNames.AUTH,
  initialState,
  reducers: {
    logout(state) {
      clearLStorageMany(...Object.values(ELStorageKeys));
      state.role = EUserRole.USER;
      state.accessToken = null;
      state.username = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, { payload: { redirect_url } }) => {
        if (redirect_url) {
          state.redirectUrl = redirect_url;
          window.location.replace(redirect_url);
        }

        state.pending = false;
      })
      .addCase(loginThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(loginThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(loggedInThunk.fulfilled, (state, { payload: { data } }) => {
        state.accessToken = data.token;
        state.username = data.user_name;
        state.id = data.account_id;
        state.redirectUrl = null;

        state.pending = false;
      })
      .addCase(getAccountMeThunk.fulfilled, (state, { payload }) => {
        state.role = getHighestRole(payload.roles);
        state.roles = payload.roles;
        state.username = payload.name;
        state.id = payload.id;
        state.pending = false;

        if (!state.isLogged) state.isLogged = true;
      })
      .addCase(getAccountMeThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAccountMeThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      });
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;

export * from './actions';
export * from './selectors';
