export const useStreamParams = () => {
  let previousBytes = 0;

  let previousFramesReceived = 0;

  const bytesToBites = (x: number) => Math.round((x * 8) / 1000);

  const getBitrate = (value: number) => {
    const currentBitrate = bytesToBites(value - previousBytes);

    if (!isNaN(currentBitrate)) {
      previousBytes = value;

      return currentBitrate;
    }
  };

  const getFramesPerSecond = (fps: number, framesReceived: number) => {
    const currentFramesPerSecond =
      fps ?? framesReceived - previousFramesReceived;

    if (!isNaN(currentFramesPerSecond)) {
      previousFramesReceived = fps ?? framesReceived;

      return currentFramesPerSecond;
    }
  };

  return {
    getBitrate,
    getFramesPerSecond,
  };
};
