import { FC, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useBreakPoint, useClickOutside } from 'hooks';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { TNavItem } from 'types';

interface MobileNavigationProps {
  list: TNavItem[] | null;
}

export const MobileNavigation: FC<MobileNavigationProps> = ({ list }) => {
  const [isOpen, setOpen] = useState(false);

  const { pathname } = useLocation();

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () => setOpen(false));

  const breakPoint = useBreakPoint();

  const handleClickMenu = () => {
    setOpen(!isOpen);
  };

  const activeNav =
    list?.find(({ link }) => pathname.includes(link)) || list?.[0];

  return (
    <div
      ref={containerRef}
      className={cn(
        'relative w-[170px] flex flex-row gap-[4px] cursor-pointer justify-center items-center z-[11]',
        {
          '!justify-end': breakPoint === 'mobile',
        }
      )}
      onClick={handleClickMenu}
    >
      <span
        className={cn('tpg-b1 text-tpg_title', {
          '!text-bright_product': isOpen,
        })}
      >
        {activeNav?.title}
      </span>
      <Down
        className={cn('[&>path]:fill-tpg_title', {
          '[&>path]:!fill-bright_product rotate-180': isOpen,
        })}
      />
      {isOpen && (
        <div
          className={cn(
            'flex flex-col absolute bg-ultrablack border border-solid rounded-[10px] border-tpg_light top-[25px] left-1/2 -translate-x-1/2 w-[215px] px-[16px] py-[6px]',
            {
              '!left-[-42px] !-translate-x-0': breakPoint === 'mobile',
            }
          )}
        >
          {list?.map(({ link, title }) => (
            <Link key={title} to={link}>
              <span
                className={cn('tpg-c2 text-tpg_base', {
                  '!text-bright_product': activeNav?.title === title,
                })}
              >
                {title}
              </span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
