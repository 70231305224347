import { createAsyncThunk } from '@reduxjs/toolkit';

import { notify } from 'utils';

// Respons(E) - интерфейс ответа
// Reques(T) - интерфейс запроса

export const createThunk = <IResponse, IRequest = undefined>(
  name: string,
  asyncFunc: (data: IRequest) => Promise<IResponse>,
  options?: {
    errorMessage?: string;
    callback?: (data: IResponse, request?: IRequest) => IResponse;
  }
) =>
  createAsyncThunk<IResponse, IRequest>(name, async (request, thunkAPI) => {
    try {
      const data = await asyncFunc(request);

      if (options?.callback) {
        return options.callback(data, request);
      }

      return data;
    } catch (error) {
      if (options?.errorMessage) {
        notify.error(options.errorMessage);
      }

      return thunkAPI.rejectWithValue(error);
    }
  });
