import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch } from 'hooks';
import { playerActions } from 'store';
import { useDebouncedCallback } from 'use-debounce';

import TextInput from 'components/ui/TextInput';

export const SearchByUsersTable = () => {
  const [searchValue, setSearchValue] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const containerRef = useRef<HTMLDivElement>(null);

  const search = searchParams.get('search');

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSearchValue(search || '');
  }, [search]);

  const setQuerySearch = useDebouncedCallback((value: string) => {
    dispatch(playerActions.setQuerySearch(value));

    setSearchParams({
      ...Object.fromEntries(searchParams),
      search: value,
    });
  }, 300);

  const handleChange = (value: string) => {
    setQuerySearch(value);

    setSearchValue(value);
  };

  return (
    <div ref={containerRef} className="relative flex justify-center">
      <TextInput
        value={searchValue}
        placeholder="Начните поиск"
        searchIcon
        isReset
        className={cn('w-full bg-dark max-w-[540px] xl:max-w-[300px]')}
        theme="dark"
        onChange={handleChange}
      />
    </div>
  );
};
