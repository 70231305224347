import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { recordsSelector } from 'store/slices/records';
import { getRecordThunk } from 'store/slices/records/actions';

import { Player } from 'components/Player';
import { Loader } from 'components/ui/Loader';
import { VideoDescription } from 'components/VideoDescription';

interface IRecordDisplayProps {
  recordId: string;
}

export const RecordDisplay: FC<IRecordDisplayProps> = ({ recordId }) => {
  const { selectedRecord, pending } = useAppSelector(recordsSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRecordThunk(recordId));
  }, [recordId]);

  if (pending)
    return (
      <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
    );

  return (
    <>
      {selectedRecord && (
        <>
          <Player src={selectedRecord.recordSrc} />
          <VideoDescription
            text={selectedRecord.description}
            tags={selectedRecord.tags}
          />
        </>
      )}
    </>
  );
};
