type TDictionary = Record<string, string>;

export const errorDictionary: Record<string, TDictionary> = {
  'register.conflict': {
    "Cannot change an account's email to the same value":
      'Ошибка в поле с логином: Невозможно изменить логин на то же значение',
    'Register error: user with email':
      'Ошибка в поле с логином: Пользователь с таким логином уже существует',
  },
};
