import { useParticipants } from '@livekit/components-react';

import { getWordDayForm } from 'utils';

export const Presence = () => {
  const participants = useParticipants();

  const presencePluralString = getWordDayForm(participants.length, [
    'зритель',
    'зрителя',
    'зрителей',
  ]);

  return (
    <span className="tpg-b1 text-tpg_base">{`${participants.length} ${presencePluralString}`}</span>
  );
};
