import { FC, useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { warningMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { useAppSelector, useBreakPoint, useRoomListeners } from 'hooks';
import { useStreamParams } from 'hooks/useStreamParams';
import { useToastManager } from 'hooks/useToastManager';
import {
  DisconnectReason,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
  RoomEvent,
  Track,
  VideoQuality,
} from 'livekit-client';
import { authSelector } from 'store/slices/auth/selectors';

import { ControlPanel } from '../ControlPanel';
import { defaultVolume } from '../ControlPanel/constants';

import './style.scss';

interface IViewerPlayerProps {
  livekitName?: string;
  isMuted?: boolean;
  publisherName?: string;
  clanName?: string;
  className?: string;
  height?: number;
  isMain?: boolean;
  isMainBlock?: boolean;
  isShowSwitchButtonMain?: boolean;
  onSelectMainRoom?: (name: string) => void;
  onFullscreenChange?: (flag: boolean) => void;
}

export const ViewerPlayer: FC<IViewerPlayerProps> = ({
  livekitName,
  isMuted,
  publisherName,
  clanName,
  className,
  height,
  isMain,
  isMainBlock = false,
  isShowSwitchButtonMain,
  onSelectMainRoom,
  onFullscreenChange,
}) => {
  const videoEl = useRef<HTMLVideoElement>(null);
  const playerEl = useRef<HTMLDivElement>(null);

  const { role } = useAppSelector(authSelector);

  const [params, setParams] = useState({
    bitrate: 0,
    packetsLost: 0,
    framesPerSecond: 0,
    frameWidth: 0,
    frameHeight: 0,
  });

  const [videoPublication, setVideoPublication] =
    useState<RemoteTrackPublication | null>(null);

  const { getBitrate, getFramesPerSecond } = useStreamParams();

  const breakPoint = useBreakPoint();

  const { showToast } = useToastManager(3);

  const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';

  const attachTracks = useCallback(
    (track: RemoteTrack, publication: RemoteTrackPublication) => {
      if (videoEl.current) {
        const ref = videoEl.current;

        track.attach(ref);

        if (isMuted) {
          ref.muted = true;
          ref.volume = 0;
        }
      }

      if (track.kind === Track.Kind.Video) {
        isMain
          ? publication.setVideoQuality(VideoQuality.HIGH)
          : publication.setVideoQuality(VideoQuality.MEDIUM);

        setVideoPublication(publication);
      }

      const videoTrack = track as RemoteVideoTrack;

      if (role === EUserRole.ROOT) {
        const updateStats = async () => {
          const stats = await videoTrack.receiver?.getStats();

          stats?.forEach((stat) => {
            if (stat.kind === 'video') {
              const currentBitrate = getBitrate(stat.bytesReceived);

              const currentFramesPerSecond = getFramesPerSecond(
                stat?.framesPerSecond,
                stat.framesReceived
              );

              if (currentBitrate) {
                setParams((prevParams) => ({
                  ...prevParams,
                  bitrate: currentBitrate,
                }));
              }

              if (stat.packetsLost) {
                setParams((prevParams) => ({
                  ...prevParams,
                  packetsLost: stat.packetsLost,
                }));
              }

              if (currentFramesPerSecond) {
                setParams((prevParams) => ({
                  ...prevParams,
                  framesPerSecond: currentFramesPerSecond,
                }));
              }

              if (stat.frameWidth && stat.frameHeight) {
                setParams((prevParams) => ({
                  ...prevParams,
                  frameWidth: !isNaN(stat.frameWidth)
                    ? Math.round(stat.frameWidth)
                    : 0,
                  frameHeight: !isNaN(stat.frameHeight)
                    ? Math.round(stat.frameHeight)
                    : 0,
                }));
              }
            }
          });
        };

        const interval = setInterval(updateStats, 1000);

        return () => {
          clearInterval(interval);
        };
      }
    },
    [videoEl]
  );

  useRoomListeners({
    [RoomEvent.TrackSubscribed]: attachTracks,
    [RoomEvent.TrackMuted]: (pub) => {
      if (pub.kind === Track.Kind.Video && pub.isMuted) {
        if (publisherName) {
          showToast('warning', `${publisherName} поставил эфир на паузу`);

          return;
        }

        showToast('warning', warningMessages.PAUSE_STREAM);
      }
    },
    [RoomEvent.TrackUnmuted]: (pub) => {
      if (pub.kind === Track.Kind.Video && !pub.isMuted) {
        if (publisherName) {
          showToast('warning', `${publisherName} возобновил эфир`);

          return;
        }

        showToast('warning', warningMessages.RESUME_STREAM);
      }
    },
    [RoomEvent.Connected]: () => {
      console.log('Connected to the room:>>', livekitName);
    },
    [RoomEvent.ConnectionStateChanged]: (evt) => {
      console.log(`Connection state changed for room:>> ${evt}`, livekitName);
    },
    [RoomEvent.SignalConnected]: () => {
      console.log('Signal connected for room:>>', livekitName);
    },
    [RoomEvent.Disconnected]: (pub) => {
      console.log('Disconnected from the room:>>', livekitName);

      if (typeof pub === 'number') {
        console.log('Disconnect reason :>>', DisconnectReason[pub]);

        if (pub === DisconnectReason.ROOM_DELETED) {
          if (publisherName) {
            showToast('warning', `${publisherName} завершил эфир`);

            return;
          }
        }
      }
    },
  });

  const handleQualityChange = (quality: VideoQuality) => {
    if (videoPublication) {
      videoPublication.setVideoQuality(quality);
    }
  };

  return (
    <div
      className={cn(
        'flex overflow-hidden justify-center relative w-full border-solid border-tpg_light border-[1px] rounded-[10px] bg-light',
        className
      )}
      style={{ height: height ? `${height}px` : '100%' }}
      ref={playerEl}
    >
      <video className="w-full" ref={videoEl} />
      {role === EUserRole.ROOT &&
        !isMobile &&
        (!isMainBlock || (isMainBlock && isMain)) && (
          <div className="absolute top-[14px] right-[12px] z-[1] h-[30px] flex gap-[4px] text tpg-c2">
            {!!(params.frameWidth && params.frameHeight) && (
              <span>{`${params.frameWidth} x ${params.frameHeight} `}</span>
            )}
            <span>{`${params.bitrate} kbps `}</span>
            <span>{`${params.framesPerSecond} fps `}</span>
            <span>{`${params.packetsLost} lost`}</span>
          </div>
        )}
      <div className="absolute top-[8px] left-[16px] flex flex-row pr-[15px] gap-[4px] pointer-events-none">
        <div className="px-[12px] py-[5px] bg-ultrablack rounded-[5px]">
          <span className="tpg-c2">{publisherName}</span>
        </div>
        {clanName && (
          <div className="px-[12px] py-[5px] bg-ultrablack rounded-[5px]">
            <span className="tpg-c2">{clanName}</span>
          </div>
        )}
      </div>
      {(!isMainBlock || (isMainBlock && isMain)) && (
        <ControlPanel
          playerEl={playerEl.current}
          videoEl={videoEl.current}
          initialVolume={isMuted ? 0 : defaultVolume}
          isMain={isMain}
          isShowSwitchButtonMain={isShowSwitchButtonMain}
          livekitName={livekitName}
          onFullscreenChange={onFullscreenChange}
          onQualityChange={handleQualityChange}
          onSelectMainRoom={onSelectMainRoom}
        />
      )}
    </div>
  );
};
