import { IRecordResponse } from 'api/types/record';
import { IRecord } from 'interfaces/record';

export const recordAdapter = ({
  id,
  clan_id,
  room,
  publisher_id,
  publisher_name,
  started_at,
  ended_at,
  url,
  description,
  tags,
}: IRecordResponse): IRecord => ({
  id,
  room,
  clanId: clan_id,
  startedAt: started_at,
  endedAt: ended_at,
  publisherId: publisher_id,
  publisherName: publisher_name,
  recordSrc: url,
  previewSrc: '',
  description,
  tags,
});

export const recordsAdapter = (records: IRecordResponse[]) =>
  records.map(recordAdapter);
